<template>
  <div class="guide-container">
    <div class="banner">
      <h2> راهنمای استفاده از سایت </h2>
    </div>
    <div class="text-container">
      <div class="how-works">
        <h3> نحوه ثبت نام </h3>
        <p>
          کارمزد معاملات انجام شده در کندل، بر اساس سطح کاربری از سفارش گذار و سفارش پذیر در زمان معامله کسر میگردد. باید در نظر داشت که افزایش سطح کاربری با در نظر
          گرفتن افزایش میزان معاملات ارتقا میابد. هم چنین بررسی میزان معاملات و افزایش سطح کاربری در هر ۲۴ ساعت یک بار به صورت اتومات انجام و سطوح کاربری ارتقا میابد.
          بر همین اساس سطوح کاربری و کارمزد
        </p>
        <!-- <div class="img-container">
          <img src="../../assets/loginPic.png" alt="">
          <img src="../../assets/loginPic.png" alt="">
          <img src="../../assets/loginPic.png" alt="">
        </div> -->
      </div>
      <div class="how-works">
        <h3> نحوه ثبت نام </h3>
        <p>
          کارمزد معاملات انجام شده در کندل، بر اساس سطح کاربری از سفارش گذار و سفارش پذیر در زمان معامله کسر میگردد. باید در نظر داشت که افزایش سطح کاربری با در نظر
          گرفتن افزایش میزان معاملات ارتقا میابد. هم چنین بررسی میزان معاملات و افزایش سطح کاربری در هر ۲۴ ساعت یک بار به صورت اتومات انجام و سطوح کاربری ارتقا میابد.
          بر همین اساس سطوح کاربری و کارمزد
        </p>
        <!-- <div class="oneimg-container">
          <img src="../../assets/loginPic.png" alt="">
        </div> -->
      </div>
      <div class="how-works">
        <h3> نحوه ثبت نام </h3>
        <p>
          کارمزد معاملات انجام شده در کندل، بر اساس سطح کاربری از سفارش گذار و سفارش پذیر در زمان معامله کسر میگردد. باید در نظر داشت که افزایش سطح کاربری با در نظر
          گرفتن افزایش میزان معاملات ارتقا میابد. هم چنین بررسی میزان معاملات و افزایش سطح کاربری در هر ۲۴ ساعت یک بار به صورت اتومات انجام و سطوح کاربری ارتقا میابد.
          بر همین اساس سطوح کاربری و کارمزد
        </p>
        <div class="oneimg-container">
<!--          <img src="../../assets/public-bg.png" alt="">-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.oneimg-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
.img-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  column-gap: 16px;
  flex-wrap: wrap;
}
.how-works{
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  h3{
    font-weight: 700;
    font-size: clamp(24px,3vw,28px);
    color: var(--darker-primary);
  }
}
.banner{
  background: url('../../assets/public-bg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  width: 100%;
  height: 336px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  //padding: 100px 0;
  h2{
    font-weight: 700;
    font-size: clamp(28px,3vw,36px);
    color: var(--black);
  }
}
.guide-container{
  display: flex;
  flex-direction: column;
  background: var(--homepage-bg);
  row-gap: 72px;
}
.text-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 72px;
  padding: 40px 120px;
}
@media only screen and(max-width:1100px){
  .text-contaienr{
    padding: 20px;
  }
  .fee-table{
    width: 100%;
  }
  .banner{
    background: unset !important;
    height: 250px !important;
  }
}

@media (max-width:500px){
  .guide-container{
    margin-top: 50px;
  }
  .banner{
    display: none;
  }
  .text-container{
    width: 100%;
    padding: 1rem !important;
  }
  h2{
    font-size: 14px;
  }

img{
  width: 80%;
  height: 80%;
  align-self: center;
}

}
</style>